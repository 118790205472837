export const Permissions = {
  transactionsAllView: 'transactions.all.view',
  transactionsAllRefund: 'transactions.all.refund',
  transactionsF2fRefund: 'transactions.face2face.refund',
  transactionsAllReverse: 'transactions.all.reverse',

  transfersAllView: 'transfers.all.view',
  transfersReferenceEdit: 'transfers.reference.edit',

  // tools
  cardMachinesDetailsView: 'card-machines.details.view',
  cardMachinesOrderCreate: 'card-machines.order.create',
  cardMachinesAdminCodesEdit: 'card-machines.admincodes.edit',
  tapDevicesDetailsView: 'tap-devices.details.view',
  businessFundingView: 'account.business-funding.view',
  businessInsightsView: 'insights.all.view',
  businessInsightsV3View: 'insights-v3.all.view',
  productAllOnboard: 'product.all.onboard',

  // payments
  paymentLinksView: 'payment-links.all.view',
  paymentLinksCreate: 'payment-links.all.create',
  virtualTerminalCreate: 'virtual-terminal-payments.all.create',

  // account
  referralAllView: 'referrals.all.view',
  teamMembersView: 'team.members.view',
  billingInvoiceView: 'billing.invoices.view',
  pciComplianceView: 'account.pci-compliance.view',
  pciComplianceConfirm: 'account.pci-compliance.confirm',

  // other
  developerPortalView: 'developer.portal.view',
  teamMembersManage: 'team.members.manage',
};
