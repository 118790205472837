import IdleTimer from '@/components/idleTimer/idleTimer';
import ProtectedPages from '@/components/protectedPages/protectedPages';
import { config } from '@/config';
import { LOCAL_STORAGE_CURRENT_CUSTOMER } from '@/utils/constants';
import { RoutePermission } from '@/utils/routePermissions';
import { ApiRoutes, Routes, RoutesNoAuth } from '@/utils/routes';
import {
  AnalyticsProvider,
  ApolloProvider,
  AuthProvider,
  BrazeProvider,
  CustomersProvider,
  InternalUserProvider,
  InviteProvider,
  LaunchDarklyProvider,
  LinksProvider,
  LoggerProvider,
  PageHeaderProvider,
  PermissionsProvider,
  UserProvider,
} from 'framework';
import { AnalyticsAction, AnalyticsCategory } from 'legacy-features';
import { LegacyProviders } from 'legacy-framework';
import { DjpErrorGeneric } from 'legacy-stencil-react';
import { ErrorBoundary } from 'react-error-boundary';
import { EligibleProductsProvider } from './EligibleProductsProvider/EligibleProductsProvider';
import { PhoneNumbersProvider } from './PhoneNumbersProvider/PhoneNumbersProvider';

export interface ProvidersProps {
  nonce: string;
  children: JSX.Element;
}

export const Providers = ({ nonce, children }: ProvidersProps) => (
  <ErrorBoundary fallback={<DjpErrorGeneric />}>
    <LoggerProvider
      logRocketAppId={config.logRocketAppId}
      appVersion={config.appVersion}
      commitHash={config.commitHash}
      serviceEnvironment={config.serviceEnvironment}
    >
      <AnalyticsProvider appVersion={config.appVersion} businessGroup={config.businessGroup}>
        <UserProvider profileUrl={ApiRoutes.profile} loginUrl={ApiRoutes.login}>
          <AuthProvider
            unauthedRoutes={RoutesNoAuth}
            routes={{ login: Routes.login, loginApi: ApiRoutes.login, logoutApi: ApiRoutes.logout }}
            onLogout={(analytics) => analytics.clickedElement(AnalyticsCategory.account, AnalyticsAction.loggedOut)}
          >
            <ApolloProvider routes={{ graphql: ApiRoutes.graphql, graphqlUploadFile: ApiRoutes.graphqlUploadFile }}>
              <ProtectedPages noAuthChildren={children}>
                <InternalUserProvider internalUserHomeRoute={Routes.internalUserHome}>
                  <InviteProvider inviteRoute={Routes.invite}>
                    <CustomersProvider unauthedRoutes={RoutesNoAuth} localStorageId={LOCAL_STORAGE_CURRENT_CUSTOMER}>
                      <LaunchDarklyProvider
                        clientSideId={config.launchDarklyClientSideID}
                        sdkKey={config.launchDarklySDKKey}
                        serviceEnvironment={config.serviceEnvironment}
                      >
                        <LinksProvider>
                          <PhoneNumbersProvider>
                            <PermissionsProvider routePermissions={RoutePermission}>
                              <BrazeProvider nonce={nonce} enabled={config.brazeEnabled}>
                                <EligibleProductsProvider>
                                  <PageHeaderProvider>
                                    <IdleTimer />
                                    {/* TODO: only mount LegacyProviders for Stencil features */}
                                    <LegacyProviders featureFlags={config.featureFlags} userIdentityGatewayPath={config.userIdentityGatewayPath}>
                                      {children}
                                    </LegacyProviders>
                                  </PageHeaderProvider>
                                </EligibleProductsProvider>
                              </BrazeProvider>
                            </PermissionsProvider>
                          </PhoneNumbersProvider>
                        </LinksProvider>
                      </LaunchDarklyProvider>
                    </CustomersProvider>
                  </InviteProvider>
                </InternalUserProvider>
              </ProtectedPages>
            </ApolloProvider>
          </AuthProvider>
        </UserProvider>
      </AnalyticsProvider>
    </LoggerProvider>
  </ErrorBoundary>
);
