import { FEATURE_INSIGHTS_V3 } from '@/utils/launchDarklyFlags';
import { Permissions } from '@/utils/permissions';
import { useLaunchDarkly, usePermissions } from 'framework';

export default function useShowInsightsV3() {
  const { permissions } = usePermissions();
  const { getFlagValue } = useLaunchDarkly();

  const featureFlagEnabled = getFlagValue(FEATURE_INSIGHTS_V3) === 'true';
  const hasPermission = permissions?.some((permission) => permission.name === Permissions.businessInsightsV3View && permission.enabled);

  return featureFlagEnabled && hasPermission;
}
