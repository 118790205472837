// List of Launch Darkly flags as constants
export const FEATURE_AUTH0 = 'feature_auth0';
export const FEATURE_RMS = 'feature_rms';
export const FEATURE_NEW_ONLINE_CHECKOUT = 'feature_new_online_checkout';
export const FEATURE_RMS_ADDITIONAL_LOCATIONS = 'feature_rms_additional_locations';
export const FEATURE_DOJO_CARD_INTEREST = 'feature_dojo_card_interest';
export const FEATURE_ONBOARDING_FLOW = 'feature_onboarding_flow';
export const FEATURE_EDIT_SUPERVISOR_CODE_LOCATION = 'feature_edit_supervisor_code_location';
export const FEATURE_INSIGHTS_V3 = 'feature_insights_v3';
export const FEATURE_CUSTOMER_INSIGHTS = 'feature_customer_insights';
